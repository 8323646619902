/* 全体のコンテナ */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Arial', sans-serif;
  height: 100vh;
  justify-content: center;
}

/* チャットコンテナ */
.chat-container {
  width: 80%;
  max-height: 60vh;
  overflow-y: auto;
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* メッセージ */
.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
}

/* 入力コンテナ */
.input-container {
  display: flex;
  justify-content: center;
  width: 80%;
}

/* 入力欄 */
.input {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 10px;
}

/* 送信ボタン */
.button {
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #007BFF;
  color: white;
  border: none;
  cursor: pointer;
}

